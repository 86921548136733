import gql from 'graphql-tag';

const puestosTableQuery = gql`
    query puestos($whereConditions:PuestosWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        puestos(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empresa_id,
                sucursal_id,
                nombre,
                objetivo,
                objetivo_ias,
                funciones,
                sueldo,
                sueldo_maximo,
                plazas,
                activo,
                clave
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { puestosTableQuery };

export default queries;
