<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>      
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-overlay :value="overlayDesgarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-overlay :value="dialogOverlaySocket" :absolute="true" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{'Subiendo puestos'}} - {{socketPorcentaje}} %</p>
                        <p>Procesados {{socketProcesados}} de {{socketTotal}}</p>
                    </div>
                </v-overlay> 
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Puestos'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd" 
                                    right
                                    rounded 
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template
                            v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'"
                            slot="actionButtonsLeft"
                        >
                            <v-menu offset-y content-class="menuClassAcciones">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones 
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                    
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div class="btnAccionesTitulo pb-6">
                                        <span>Layout de puestos</span>
                                    </div>

                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <div @click="abrirModalLayoutPuestos('descargar')" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="abrirModalLayoutPuestos('subir')" class="btnAccionesItem py-2">
                                            <span>Importar layout para agregar puestos</span><br />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div @click="layout()" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="modalLayout()" class="btnAccionesItem py-2">
                                            <span>Importar layout para agregar puestos</span><br />
                                        </div>
                                    </template>
                                    <div class="btnAccionesTitulo pb-6">
                                        <span>Layout con información de los puestos</span>
                                    </div>
                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <div @click="abrirModalLayoutPuestos('exportarMasivo')" class="btnAccionesItem py-2">
                                            <span>Exportar carga masiva</span>
                                        </div>
                                        <div @click="abrirModalLayoutPuestos('updateMasivo')" class="btnAccionesItem py-2">
                                            <span>Actualizar carga masiva</span><br />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div @click="exportarPuestosMasivo()" class="btnAccionesItem py-2">
                                            <span>Exportar carga masiva</span>
                                        </div>
                                        <div @click="modalLayout('subirMasivo')" class="btnAccionesItem py-2">
                                            <span>Actualizar carga masiva</span><br />
                                        </div>
                                    </template>
                                </div>
                            </v-menu>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Nombre de puesto"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="6" md="12" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value"
                                    ></v-autocomplete>
                                </v-col>
                                
                                <v-col xs="12" sm="12" md="6" class="py-0">
                                <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave}}</div></td>
                                <td><div>{{item.nombre}}</div></td>
                                <td><div>{{formatoMoneda(item.sueldo)}}</div></td>
                                <td><div>{{formatoMoneda(item.sueldo_maximo)}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModalDuplicar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">content_copy</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Duplicar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template>
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar>
                        <v-card elevation="0">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        required
                                                        :error-messages="errors" >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        required 
                                                        :error-messages="errors" >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Sucursal"
                                                        :items="sucursales"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="sucursal_value"
                                                        required 
                                                        :error-messages="errors" >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'admin-empresa'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Sucursal"
                                                        :items="sucursales"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="sucursal_value"
                                                        required 
                                                        :error-messages="errors" >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Nombre de puesto" 
                                                        v-model="puesto.nombre" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <!--<v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Objeto SYS" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Objeto SYS" 
                                                        v-model="puesto.objetivo" 
                                                        :error-messages="errors" 
                                                        required
                                                    >   
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <v-text-field 
                                                    outlined 
                                                    label="Objeto IAS" 
                                                    v-model="puesto.objetivo_ias" 
                                                >
                                                </v-text-field>
                                            </v-col>-->
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Funciones" rules="required">
                                                    <v-textarea 
                                                        outlined 
                                                        label="Funciones" 
                                                        class="pa-0 ma-0" 
                                                        v-model="puesto.funciones" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-textarea>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="5" class="py-0">
                                                <VuetifyMoney
                                                    v-model="sueldo"
                                                    label="Sueldo mínimo sugerido"
                                                    outlined 
                                                    v-bind:options="options"
                                                    :rules="[rules.requiredSueldo]"
                                                    ref="sueldo"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                <VuetifyMoney
                                                    v-model="sueldo_maximo"
                                                    label="Sueldo máximo"
                                                    outlined 
                                                    v-bind:options="options"
                                                    :rules="[rules.requiredSueldoMax]"
                                                    ref="sueldoMaximo"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Plazas" rules="required|numeros_positivos">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Plazas" 
                                                        v-model="puesto.plazas" 
                                                        :error-messages="errors" 
                                                        required
                                                        v-mask="'######'"
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                       @click="cerrarModal()" 
                                       :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid || !bSueldo || !bSueldoMaximo"  
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>



            <template>
                <v-dialog v-model="dialogLayout" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayout"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <div id="padre" v-show="isLoadingLayout">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingLayout" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo.archivo"
                                                    accept=".xlsx"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" v-if="accionLayout == 'subir'"  @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout == 'subirMasivo'"  @click="actualizacionMasiva()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Errores de puestos</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoadingError">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingError" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header" v-if="erroresModal.length > 0">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Puesto</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Tipo de error</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="observacionesModal.length == 0">
                                                <tr v-if="erroresModal.length == 0" >
                                                    <td class="emptyTableErrores" colspan="2">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresModal">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.nombre}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.comentarios}}</div> </td>
                                                        </tr>
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template v-for="(observacion, index) in observacionesModal">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{observacion}}</div>
                                                            </td>
                                                        </tr>
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialogLayoutPuestos" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayoutEmpleados"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingLayout">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingLayout" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    required
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empresa"
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="accionLayout === 'subir' || accionLayout == 'updateMasivo'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo.archivo"
                                                    accept=".xlsx"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'subir'" @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'descargar'" @click="layout()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'exportarMasivo'" @click="exportarPuestosMasivo()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'updateMasivo'" @click=" actualizacionMasiva()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogDuplicar" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayoutEmpleados"></v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingDuplicar">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingDuplicar" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="itemsSucursales"
                                            :valorDefecto="modelSucursales"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> modelSucursales = data"
                                            label="Sucursales"
                                            ref="multCocinas"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                            <v-btn class="btnGuardar" @click="duplicarPuesto()" :loading="isSaving">Duplicar</v-btn>    
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>
<script>
import Vue from "vue";
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Puestos';
import clienteAPi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import sucursalApi from '@/api/sucursales';
import Notify from '@/plugins/notify';
import apiPuesto from '@/api/nominas/puestos';
import VuetifyMoney from '@/components/VuetifyMoney.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';

import env from "@/plugins/enviroment";
import Echo from "laravel-echo";
window.Pusher = require('pusher-js');

export default {
    components: {
        'data-table': Datatable,
        VuetifyMoney,
        VuetifyMultipleAutocomplete
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: false
                },
                {
                    label: 'Nombre de puesto',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Sueldo mínimo sugerido',
                    name: 'sueldo',
                    filterable: false
                },
                {
                    label: 'Sueldo máximo',
                    name: 'Sueldo_maxímo',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align:'center',
                    filterable: false
                }
            ],
            filters         : { AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            puesto              : {
                id              : null,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                nombre          : '',
                objetivo        : '',
                objetivo_ias    : null,
                funciones       : '',
                sueldo          : 0,
                sueldo_maximo   : 0,
                plazas          : 0
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            imagen          : "",
            dialog          : false,
            isSaving        : false,
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            rol             :null,
            nombre          : null,
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            rules: {
                requiredSueldo: value => !!value || 'El campo Sueldo es obligatorio.',
                requiredSueldoMax: value => !!value || 'El campo Sueldo máximo es obligatorio.',
                min: v => v.length >= 8 || 'Min 8 caracteres',
            },
            sueldo              : 0,
            sueldo_maximo       : 0,
            bSueldo             : false,
            bSueldoMaximo       : false,
            isLoadingModal      : false,
            frase                   :'Descargando archivo',
            overlayDesgarga         : false,
            archivo             : {
                archivo         : null,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                rol_name        : null,
                tipo            : 'nominas'
            },
            dialogLayout        : false,
            dialogLayoutPuestos : false,
            isLoadingLayout     : false,
            accionLayout        : 'descargar',
            erroresModal            : [],
            observacionesModal : [],
            dialogErrores           : false,
            isLoadingError          : false,

            dialogOverlaySocket     : false,
            socketPorcentaje        : 0,
            socketTotal             : 0,
            socketProcesados        : 0,
            procesados              : 0,
            conError                : 0,

            dialogDuplicar          : false,
            isLoadingDuplicar       : false,
            itemsSucursales         : [],
            modelSucursales         : [],
            duplicarPuestoId        : null,

        }
    },
    watch: {
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
        empresa_value: function(val){
            this.cambiaEmpresa(val);
        },
        sueldo: function(val){
            if(val){
                this.bSueldo=true;
            }
            else{
                this.bSueldo=false;
            }
        },
        sueldo_maximo: function(val){
            if(val){
                this.bSueldoMaximo=true;
            }
            else{
                this.bSueldoMaximo=false;
            }
        }
    },
    methods: {
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            if (this.rol == "root") {
                this.getClientes();
            }
 
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.cliente_value});
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.filters.AND.push({column:'EMPRESA_ID', value:this.empresa_value});
            }

            if(this.rol == "admin-sucursal"){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.filters.AND.push({column:'SUCURSAL_ID', value:this.sucursal_value});
            }
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresModal = [];
            this.observacionesModal = []
        },
        async abrirModal(accion, data = null){
            await this.resetValues();
            this.accion     = accion;
            
            this.isLoadingModal  = true;

            this.getClientes();
            if(accion == "add"){
                this.tituloModal = "Registrar puesto";
                this.imagen = "/static/icon/puestosCreate.svg";
                
            } else {
                this.tituloModal = "Actualizar puesto";
                this.imagen = "/static/icon/puestosUpdate.svg";
                    this.puesto.id              = data.id;
                    this.puesto.cliente_id      = data.cliente_id;
                    this.puesto.empresa_id      = data.empresa_id;
                    this.puesto.sucursal_id     = data.sucursal_id;
                    this.cliente_value          = data.cliente_id;
                    this.empresa_value          = data.empresa_id;
                    this.sucursal_value         = data.sucursal_id;
                    this.puesto.nombre          = data.nombre;
                    this.puesto.objetivo        = data.objetivo;
                    this.puesto.objetivo_ias    = data.objetivo_ias;
                    this.puesto.funciones       = data.funciones;
                    this.puesto.sueldo          = data.sueldo;
                    this.sueldo                 = data.sueldo;
                    this.puesto.sueldo_maximo   = data.sueldo_maximo;
                    this.sueldo_maximo          = data.sueldo_maximo;
                    this.puesto.plazas          = data.plazas;

            }

            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog             = false;
            this.isSaving           = false;
            this.dialogLayout       = false;
            this.dialogLayoutPuestos= false;
            this.isLoadingLayout    = false;

            this.dialogDuplicar      = false;
            this.isLoadingDuplicar   = false;
            
            this.resetValues();
        },
        resetValues() {
            this.puesto         = {
                id              : null,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                nombre          : '',
                objetivo        : '',
                objetivo_ias    : null,
                funciones       : '',
                sueldo          : 0,
                sueldo_maximo   : 0,
                plazas          : 0
            };
            this.archivo        = {
                archivo         : null,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                rol_name        : null,
                tipo            : 'nominas'
            };

            this.itemsSucursales  = [];
            this.modelSucursales  = [];
            this.duplicarPuestoId = null;

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                } 
                if(this.rol == 'root'){
                    this.cliente_value      = null;
                    this.empresa_value      = null;
                    this.sucursal_value     = null;
                    this.empresas           = [];
                    this.sucursales         = [];
                }

                if(this.rol == 'admin'){
                    this.empresa_value      = null;
                    this.sucursal_value     = null;
                    this.sucursales         = [];
                } 

                if(this.rol == 'admin-empresa'){
                    this.sucursal_value     = null;
                }

                this.nombre             = null;
                this.sueldo             = 0;
                this.sueldo_maximo      = 0;

            });
        },
        async loadModalData() {
            this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value = null;
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = null;
                this.sucursal_value = null;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = null;
            } else if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
        },
        setFilters() {
            this.filters = { AND:[]};

            let nombre = this.nombre;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            }else if (this.rol == "admin-sucursal") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            } 
            
            
            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                    this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
                }

            if(cliente != null && cliente != undefined && cliente != ""){
                    this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
                }

                if(empresa != null && empresa != undefined && empresa != ""){
                    this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
                }

                if(sucursal != null && sucursal != undefined && sucursal != ""){
                    this.filters.AND.push({column:'SUCURSAL_ID', value:sucursal});
                }
            this.paginationData.numberPage = 1;
            this.$apollo.queries.puestos.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el puesto " + data.nombre + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiPuesto.delete(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El puesto ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.puestos.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false }
            clienteAPi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch(err => {
                this.resetValues();
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            });
        },
        cambiaCliente(id, cliente = null) {
            if(this.puesto.cliente_id != id){
                //this.empresa_value = null;
                this.puesto.empresa_id = null;
            }

            if(id == null){
                return;
            }

            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response)=>{
                this.empresas = response.data;
                if(this.puesto.empresa_id != null){
                    this.empresa_value = this.puesto.empresa_id;
                }
            })
            .catch(err => {
                this.resetValues();
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            });
        },
        cambiaEmpresa(id, sucursal = null) {
            if(this.puesto.empresa_id != id){
                this.sucursal_value = null;
                this.puesto.sucursal_id = null;
            }

            if(id == null){
                return;
            }
            
            let parametros = { activo:true, paginate: false, empresa_id: id }
            sucursalApi.getSucursales(parametros).then((response)=>{
                this.sucursales = response.data;
                if(this.puesto.sucursal_id != null){
                    this.sucursal_value= this.puesto.sucursal_id;
                }
            })
            .catch(err => {
                this.resetValues();
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(parseFloat(this.sueldo) > parseFloat(this.sueldo_maximo)){
                    Notify.Warning("Error", "El sueldo es mayor al sueldo máximo." );
                    this.isSaving = false;
                    return;
                }
                this.puesto.cliente_id      = this.cliente_value;
                this.puesto.empresa_id      = this.empresa_value;
                this.puesto.sucursal_id     = this.sucursal_value;
                this.puesto.sueldo          = this.sueldo;
                this.puesto.sueldo_maximo   = this.sueldo_maximo;

                if(this.accion === "add") {
                    apiPuesto.store(this.puesto)
                    .then((response) => {
                        this.isLoading =  true;
                        Notify.Success("Operación exitosa", "El Puesto se guardó satisfactoriamente.");
                        this.$apollo.queries.puestos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiPuesto.update(this.puesto)
                    .then((response) => {
                        this.isLoading =  true;
                        Notify.Success("Operación exitosa", "El Puesto se actualizó satisfactoriamente.");
                        this.$apollo.queries.puestos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },

        formatoMexico(value){
            const exp = /(\d)(?=(\d{3})+(?!\d))/g;
            const rep = '$1,';
            let arr = value.toString().split('.');
            arr[0] = arr[0].replace(exp,rep);
            return arr[1] ? arr.join('.'): arr[0];
        },
        open(){
            document.getElementById("fileDocument").click();
        },
        abrirModalLayoutPuestos(accion){
            this.isLoadingLayout = true;
            this.accionLayout = accion;
            this.dialogLayoutPuestos = true;
            if(accion === 'descargar' ){
                this.tituloModal  = 'Descargar layout para agregar puestos';
            }
            else if(accion === 'subir'){
                this.tituloModal  = 'Subir layout para agregar puestos';
            }
            else if (accion === 'exportar'){
                this.tituloModal  = 'Exportar layout de puestos';
            }
            else if(accion == 'exportarMasivo'){
                this.tituloModal = 'Exportar layout de puestos'
            }
            else if(accion == 'updateMasivo'){
                this.tituloModal = 'Actualización masiva'
            }
            if (this.rol == "admin") {
                this.archivo.cliente_id = this.datosLogin.cliente_id;
                this.cliente_value = this.datosLogin.cliente_id;
            }
            if(this.rol == "root"){
                this.getClientes();
            }
            this.isLoadingLayout = false;
        },
        modalLayout(accion) {
            this.accionLayout = accion

            if(accion == 'subir'){
                this.tituloModal = "Subir layout para agregar puestos";
            }else if(accion == 'subirMasivo'){
                this.tituloModal = "Actualización masiva"
            }
            this.archivo.cliente_id = this.datosLogin.cliente_id;
            this.archivo.empresa_id = this.datosLogin.empresa_id;
            if (this.rol == "admin-sucursal") {
                this.archivo.sucursal_id = this.datosLogin.sucursal_id;
            }
            this.archivo.rol_name = this.datosLogin.rol.name;

            setTimeout(function() {
                document.getElementById("btnModalLayout").click();
            }, 300);
        },
        async layout() {
            let self = this;
            let url = "catalogo/puestos/layout";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre;
            if (this.rol == "admin" || this.rol == "root") {
                this.isSaving = true;
                nombre =
                    "layout_de_importacion_de_puestos_" +
                    today.getDate() +
                    "_" +
                    (today.getMonth() + 1) +
                    "_" +
                    today.getFullYear() +
                    "_" +
                    today.getHours() +
                    "-" +
                    today.getMinutes() +
                    "-" +
                    today.getSeconds() +
                    ".xlsx";
                var object1 = {
                    rol_name: "admin-empresa",
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                };
            }
            if (this.rol == "admin-empresa") {
                this.overlayDesgarga = true;
                nombre =
                    "layout_de_importacion_de_puestos_" +
                    today.getDate() +
                    "_" +
                    (today.getMonth() + 1) +
                    "_" +
                    today.getFullYear() +
                    "_" +
                    today.getHours() +
                    "-" +
                    today.getMinutes() +
                    "-" +
                    today.getSeconds() +
                    ".xlsx";
                var object1 = {
                    rol_name: this.rol,
                    cliente_id: this.datosLogin.cliente_id,
                    empresa_id: this.datosLogin.empresa_id,
                };
            }
            if (this.rol == "admin-sucursal") {
                this.overlayDesgarga = true;
                nombre =
                   "layout_de_importacion_de_puestos_" +
                    today.getDate() +
                    "_" +
                    (today.getMonth() + 1) +
                    "_" +
                    today.getFullYear() +
                    "_" +
                    today.getHours() +
                    "-" +
                    today.getMinutes() +
                    "-" +
                    today.getSeconds() +
                    ".xlsx";
                var object1 = {
                    rol_name: this.rol,
                    cliente_id: this.datosLogin.cliente_id,
                    empresa_id: this.datosLogin.empresa_id,
                    sucursal_id: this.datosLogin.sucursal_id,
                };
            }
            object1.sistema = "nominas";
            try {
                await Vue.axios({
                    method: "GET",
                    responseType: "blob",
                    url: url,
                    params: object1
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.cerrarModal();
                    this.overlayDesgarga = false;
                });
            } catch (e) {
                this.isSaving = false;
                console.log(e);
            }
        },
        guardarLayout() {
            let self = this;
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                if(this.rol == "root" || this.rol == "admin"){
                    this.archivo.cliente_id = this.cliente_value;
                    this.archivo.empresa_id = this.empresa_value;
                    this.archivo.rol_name   = "admin-empresa";
                }
                let formData = new FormData();
                formData.append("archivo", this.archivo.archivo);
                formData.append("cliente_id", this.archivo.cliente_id);
                formData.append("empresa_id", this.archivo.empresa_id);
                formData.append("tipo", this.archivo.tipo);

                if (this.rol == "admin-sucursal") {
                    formData.append("sucursal_id", this.archivo.sucursal_id);
                }

                formData.append("rol_name", this.archivo.rol_name);
                formData.append("usuario_id", this.datosLogin.id);
                let url = "catalogo/puestos/importar";
                const FileDownload = require("js-file-download");
                const config = {
                    headers: {
                        responseType: "blob"
                    }
                };
                
                Vue.axios({
                    method: "post",
                    url: "catalogo/puestos/importar",
                    data: formData,
                    config: { headers: { "Content-Type": "multipart/form-data" } }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        //Notify.Success("Operación exitosa", "Se ha realizado la operación correctamente.");
                        self.cerrarModal();
                    }
                })
                .catch(err => {
                    this.isSaving = false;
                    Notify.ErrorToast("Error al subir archivo.");
                    this.$apollo.queries.puestos.refetch();
                });
            });
        },

        socketProcesandoPuestos(){
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'ASDASD2121',
                wsHost: env.serverURL.url,
                wsPort: env.pusherPort,
                wssPort: env.pusherPort,
                forceTLS: env.serverURL.tls,
                disableStats: true,
                enabledTransports: ['ws', 'wss']
            });

            window.Echo.channel(`progreso-importar-puesto-${this.datosLogin.id}`).listen('ImportarPuestoProgress', (e) => {
                console.log(e)
                this.dialogOverlaySocket = true;
                this.socketPorcentaje   = e.message.porcentaje;
                this.socketTotal        = e.message.total;      
                this.socketProcesados   = e.message.procesados;
                if(e.message.porcentaje == 100){
                    this.procesados = e.message.procesados;
                    this.conError   = e.message.errores;

                    if(e.message.errores.length == 0){
                        this.dialogOverlaySocket = false;
                        Notify.Success("Operación exitosa");
                        this.$apollo.queries.puestos.refetch();
                    }
                    else{
                        this.dialogOverlaySocket = false;
                        this.erroresModal = e.message.errores;
                        this.dialogErrores = true;
                        this.$apollo.queries.puestos.refetch();
                    }

                    setTimeout(() => {
                        this.resetProgresoPuestos();
                    },5000);
                }
            });
        },
        resetProgresoPuestos(){
            this.socketPorcentaje       = 0;
            this.socketTotal            = 0;
            this.socketProcesados       = 0;
            this.procesados             = 0;
            this.conError               = 0;
        },
        async getSucursales(data){
            let parametros = { activo:true, paginate: false, empresa_id: data.empresa_id }
            await sucursalApi.getSucursales(parametros).then((response)=>{
                let format = response.data
                this.itemsSucursales = format.map(item => {
                    if(item.id != data.sucursal_id){
                        return item;
                    } 
                } );
            })
            .catch(err => {
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            });
        },
        async abrirModalDuplicar(data){
            this.tituloModal = "Duplicar puesto";
            this.dialogDuplicar = true;
            this.isLoadingDuplicar = true;
            await this.getSucursales(data);
            this.isLoadingDuplicar = false;
            this.duplicarPuestoId = data.id;

        },
        duplicarPuesto(){
            this.isSaving =  true;
            if(this.modelSucursales.length == 0){
                Notify.ErrorToast("Selecciona al menos una sucursal.");
                this.isSaving =  false;
                return;
            }

            let parametros = {
                puesto_id: this.duplicarPuestoId, 
                sucursales: this.modelSucursales 
            }
            apiPuesto.duplicar(parametros).then((response) => {
                this.isSaving = false;
                this.isLoading =  true;
                Notify.Success("Operación exitosa", "El Puesto se duplico satisfactoriamente.");
                this.$apollo.queries.puestos.refetch();
                this.cerrarModal();
            })
            .catch(err => {
                this.isSaving = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });

        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        exportarPuestosMasivo() {
            this.overlayDesgarga = true
            let url = "catalogo/puestos/export-masivo"
            const FileDownload = require("js-file-download")
            let today = new Date()
            let nombre
            this.isSaving = true

            if (this.rol == "root" || this.rol == "admin") {
                this.$refs.form.validate().then((success) => {
                    if (!success) {
                        this.isSaving = false
                        return
                    }
                    nombre = `puestos_${today.getDate()}_${today.getMonth() + 1}_${today.getFullYear()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}.xlsx`

                    let parametros = {
                        rol_name: 'admin-empresa',
                        cliente_id: this.cliente_value,
                        empresa_id: this.empresa_value,
                        sistema: 'nominas'
                    }

                    try {
                        Vue.axios({
                            method: "GET",
                            responseType: "blob",
                            url: url,
                            params: parametros
                        }).then((response) => {
                            if (response.status == 200) {
                                FileDownload(response.data, nombre)
                                this.overlayDesgarga = false
                                this.cerrarModal()
                            } else {
                                Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico.")
                                this.overlayDesgarga = false
                                this.cerrarModal()
                            }
                        })
                    } catch (error) {
                        console.log('Hubo un error:', error)
                        this.overlayDesgarga = false
                        this.cerrarModal()
                    }
                })
            } else {
                nombre = `puestos_${today.getDate()}_${today.getMonth() + 1}_${today.getFullYear()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}.xlsx`

                let parametros = {
                    rol_name: 'admin-empresa',
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                    sistema: 'nominas'
                }

                try {
                    Vue.axios({
                        method: "GET",
                        responseType: "blob",
                        url: url,
                        params: parametros
                    }).then((response) => {
                        if (response.status == 200) {
                            FileDownload(response.data, nombre)
                            this.overlayDesgarga = false
                        } else {
                            Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico.")
                            this.overlayDesgarga = false
                        }
                    })
                } catch (error) {
                    console.log('Hubo un error:', error)
                    this.overlayDesgarga = false
                }
            }

        },
        actualizacionMasiva(){
            this.$refs.form.validate().then((success) => {
                this.isSaving = true
                if(!success){
                    this.isSaving = false
                    return
                }
                if(this.rol == "root" || this.rol == "admin"){
                    this.archivo.cliente_id = this.cliente_value
                    this.archivo.empresa_id = this.empresa_value
                    this.archivo.rol_name = "admin-empresa"
                }
                let formData = new FormData()
                formData.append("archivo", this.archivo.archivo)
                formData.append("cliente_id", this.archivo.cliente_id)
                formData.append("empresa_id", this.archivo.empresa_id)
                formData.append("sistema", "nominas")
                formData.append("rol_name", this.archivo.rol_name)

                apiPuesto.updateMasivo(formData).then((response) => {
                    if(response.status == 200){
                        this.cerrarModal()
                        Notify.Success("Operación exitosa","Se actualizaron correctamente los puestos")
                        this.$apollo.queries.puestos.refetch()
                    }else if(response.status == 202){
                        this.observacionesModal = response.data.observaciones
                        this.dialogErrores = true
                        this.cerrarModal()
                        this.$apollo.queries.puestos.refetch()
                    }
                }).catch((err) => {
                    this.cerrarModal()
                    Notify.ErrorToast("Error con el archivo subido.")
                })
            })
        },
    },
    mounted() {
        this.$apollo.queries.puestos.skip = false;
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        this.socketProcesandoPuestos();
        this.listar();
    },
    apollo: {
        puestos: {
            query       : queries.puestosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.puestos;   
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-block: 30px;
        background-color: #FFF;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }

    #btnModalErrores{
        display: none;
    }

    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }
</style>

